import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import PageInfo from "../components/pageInfo";

const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

const NotFoundPage = ({ data: { spools } }) => {
  const pageTitle = "Page Not Found";

  return (
    <Layout>
      <PageInfo title={pageTitle} />
      <h1 className="text-6xl text-center mb-10 font-new-tegomin">
        {pageTitle}
      </h1>

      <div className="max-w-xl mx-auto mb-10 bg-studio-burnt-umber image-frame">
        <GatsbyImage image={getImage(spools)} alt="empty thread spools" />
      </div>

      <p className="p-alt text-center">
        Sorry, our spools have run empty looking for your page.{" "}
        <Link to="/">Go back home</Link>
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
      </p>
    </Layout>
  );
};

export const query = graphql`
  query {
    spools: file(relativePath: { eq: "spools.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: BLURRED, quality: 70)
      }
    }
  }
`;

export default NotFoundPage;
